export const state = () => ({
    showForm: false,
    signedUp: false,
})

export const mutations = {
    OPEN (state) {
        state.showForm = true
    },
    CLOSE (state) {
        state.showForm = false
    },
    SIGNED_UP (state) {
        state.signedUp = true
        localStorage.setItem('signedUp', true)
    },
}

export const actions = {
    show (context) {
        context.commit('OPEN')
    },
    close (context) {
        context.commit('CLOSE')
    },
    signedUp (context) {
        context.commit('SIGNED_UP')
    },
}

export const getters = {
    showForm: state => state.showForm,
    signedUp: state => state.signedUp,
}
