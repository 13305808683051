
import handleToken from '@/mixins/handleToken'
import getWidgetVariation from '@/mixins/getWidgetVariation'

const stepButtonMap = {
    1: 'FORM.FIND_OUT_MORE',
    2: 'FORM.NEXT',
    3: 'FORM.SUBMIT',
}
export default {
    mixins: [handleToken, getWidgetVariation],
    props: {
        closable: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            form: {
                name: '',
                email: '',
                inDubai: true,
                budget: '<=200k',
                phone: '',
                countryCode: '',
            },
            maxSteps: 3,
            currentStep: 1,
            loading: false,
        }
    },
    computed: {
        canContinue () {
            return this.currentStep === 3 ? Boolean(this.form.phone) : true
        },
        buttonText () {
            return this.$t(stepButtonMap[this.currentStep])
        },
    },
    watch: {
        '$store.state.signedUp': {
            handler (value) {
                if (value) {
                    setTimeout(() => {
                        this.currentStep = 4
                    })
                }
            },
            immediate: true,
        },
    },
    mounted () {
        if (localStorage.getItem('signedUp')) {
            this.$store.dispatch('signedUp')
        }
    },
    methods: {
        updateCountry (countryCode) {
            this.form.countryCode = countryCode
        },
        next () {
            if (this.canContinue) { this.currentStep++ }
        },
        async submit () {
            if (!this.canContinue) { return }
            this.loading = true
            try {
                await this.$axios({
                    method: 'post',
                    url: 'submit',
                    baseURL: '/',
                    data: {
                        ...this.form,
                        ...this.getToken(),
                        locale: this.$i18n.locale,
                        fullUrl: window.location.href.split('?')[0],
                        _fbp: this.getCookie('_fbp'),
                        _fbc: this.getCookie('_fbc'),
                        userAgent: navigator.userAgent,
                    } })
                this.$gtm.push({ event: 'signUpCompleted' })
                this.$store.dispatch('signedUp')
                this.currentStep++
            } catch (err) {}
            this.loading = false
        },
    },
}
