import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=1145d96a&"
import script from "./ContactForm.vue?vue&type=script&lang=js&"
export * from "./ContactForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StepsComponent: require('/usr/src/app/components/StepsComponent.vue').default,InputDubaiSelect: require('/usr/src/app/components/input/DubaiSelect.vue').default,InputBudgetSelect: require('/usr/src/app/components/input/BudgetSelect.vue').default,InputPhone: require('/usr/src/app/components/input/Phone.vue').default})
