export default {
    mounted () {
        const utmTags = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']
        if (utmTags.some(t => this.$route.query[t]) || this.$route.query.clickid) {
            localStorage.setItem('token', JSON.stringify(this.$route.query))
        }
    },
    methods: {
        getToken () {
            const token = localStorage.getItem('token')

            if (token) {
                return JSON.parse(token)
            }
        },
    },
}
