import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6d1a8795 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _0f71be30 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _b714ba74 = () => interopDefault(import('../pages/properties/index.vue' /* webpackChunkName: "pages/properties/index" */))
const _375c4167 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _375eaf2d = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _ba81fd04 = () => interopDefault(import('../pages/properties/_slug.vue' /* webpackChunkName: "pages/properties/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ar",
    component: _e542f99c,
    name: "index___ar"
  }, {
    path: "/bg",
    component: _e542f99c,
    name: "index___bg"
  }, {
    path: "/careers",
    component: _6d1a8795,
    name: "careers___en"
  }, {
    path: "/es",
    component: _e542f99c,
    name: "index___es"
  }, {
    path: "/fr",
    component: _e542f99c,
    name: "index___fr"
  }, {
    path: "/he",
    component: _e542f99c,
    name: "index___he"
  }, {
    path: "/hi",
    component: _e542f99c,
    name: "index___hi"
  }, {
    path: "/hu",
    component: _e542f99c,
    name: "index___hu"
  }, {
    path: "/ko",
    component: _e542f99c,
    name: "index___ko"
  }, {
    path: "/nl",
    component: _e542f99c,
    name: "index___nl"
  }, {
    path: "/privacy",
    component: _0f71be30,
    name: "privacy___en"
  }, {
    path: "/properties",
    component: _b714ba74,
    name: "properties___en"
  }, {
    path: "/ro",
    component: _e542f99c,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _e542f99c,
    name: "index___ru"
  }, {
    path: "/terms",
    component: _375c4167,
    name: "terms___en"
  }, {
    path: "/ur",
    component: _e542f99c,
    name: "index___ur"
  }, {
    path: "/ar/careers",
    component: _6d1a8795,
    name: "careers___ar"
  }, {
    path: "/ar/privacy",
    component: _0f71be30,
    name: "privacy___ar"
  }, {
    path: "/ar/properties",
    component: _b714ba74,
    name: "properties___ar"
  }, {
    path: "/ar/terms",
    component: _375c4167,
    name: "terms___ar"
  }, {
    path: "/bg/careers",
    component: _6d1a8795,
    name: "careers___bg"
  }, {
    path: "/bg/privacy",
    component: _0f71be30,
    name: "privacy___bg"
  }, {
    path: "/bg/properties",
    component: _b714ba74,
    name: "properties___bg"
  }, {
    path: "/bg/terms",
    component: _375c4167,
    name: "terms___bg"
  }, {
    path: "/es/careers",
    component: _6d1a8795,
    name: "careers___es"
  }, {
    path: "/es/privacy",
    component: _0f71be30,
    name: "privacy___es"
  }, {
    path: "/es/properties",
    component: _b714ba74,
    name: "properties___es"
  }, {
    path: "/es/terms",
    component: _375c4167,
    name: "terms___es"
  }, {
    path: "/fr/careers",
    component: _6d1a8795,
    name: "careers___fr"
  }, {
    path: "/fr/privacy",
    component: _0f71be30,
    name: "privacy___fr"
  }, {
    path: "/fr/properties",
    component: _b714ba74,
    name: "properties___fr"
  }, {
    path: "/fr/terms",
    component: _375c4167,
    name: "terms___fr"
  }, {
    path: "/he/careers",
    component: _6d1a8795,
    name: "careers___he"
  }, {
    path: "/he/privacy",
    component: _0f71be30,
    name: "privacy___he"
  }, {
    path: "/he/properties",
    component: _b714ba74,
    name: "properties___he"
  }, {
    path: "/he/terms",
    component: _375c4167,
    name: "terms___he"
  }, {
    path: "/hi/careers",
    component: _6d1a8795,
    name: "careers___hi"
  }, {
    path: "/hi/privacy",
    component: _0f71be30,
    name: "privacy___hi"
  }, {
    path: "/hi/properties",
    component: _b714ba74,
    name: "properties___hi"
  }, {
    path: "/hi/terms",
    component: _375c4167,
    name: "terms___hi"
  }, {
    path: "/hu/careers",
    component: _6d1a8795,
    name: "careers___hu"
  }, {
    path: "/hu/privacy",
    component: _0f71be30,
    name: "privacy___hu"
  }, {
    path: "/hu/properties",
    component: _b714ba74,
    name: "properties___hu"
  }, {
    path: "/hu/terms",
    component: _375c4167,
    name: "terms___hu"
  }, {
    path: "/ko/careers",
    component: _6d1a8795,
    name: "careers___ko"
  }, {
    path: "/ko/privacy",
    component: _0f71be30,
    name: "privacy___ko"
  }, {
    path: "/ko/properties",
    component: _b714ba74,
    name: "properties___ko"
  }, {
    path: "/ko/terms",
    component: _375c4167,
    name: "terms___ko"
  }, {
    path: "/nl/careers",
    component: _6d1a8795,
    name: "careers___nl"
  }, {
    path: "/nl/privacy",
    component: _0f71be30,
    name: "privacy___nl"
  }, {
    path: "/nl/properties",
    component: _b714ba74,
    name: "properties___nl"
  }, {
    path: "/nl/terms",
    component: _375c4167,
    name: "terms___nl"
  }, {
    path: "/ro/careers",
    component: _6d1a8795,
    name: "careers___ro"
  }, {
    path: "/ro/privacy",
    component: _0f71be30,
    name: "privacy___ro"
  }, {
    path: "/ro/properties",
    component: _b714ba74,
    name: "properties___ro"
  }, {
    path: "/ro/terms",
    component: _375c4167,
    name: "terms___ro"
  }, {
    path: "/ru/careers",
    component: _6d1a8795,
    name: "careers___ru"
  }, {
    path: "/ru/privacy",
    component: _0f71be30,
    name: "privacy___ru"
  }, {
    path: "/ru/properties",
    component: _b714ba74,
    name: "properties___ru"
  }, {
    path: "/ru/terms",
    component: _375c4167,
    name: "terms___ru"
  }, {
    path: "/ur/careers",
    component: _6d1a8795,
    name: "careers___ur"
  }, {
    path: "/ur/privacy",
    component: _0f71be30,
    name: "privacy___ur"
  }, {
    path: "/ur/properties",
    component: _b714ba74,
    name: "properties___ur"
  }, {
    path: "/ur/terms",
    component: _375c4167,
    name: "terms___ur"
  }, {
    path: "/ar/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ar"
  }, {
    path: "/ar/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___ar"
  }, {
    path: "/bg/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___bg"
  }, {
    path: "/bg/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___bg"
  }, {
    path: "/es/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___es"
  }, {
    path: "/es/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___es"
  }, {
    path: "/fr/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___fr"
  }, {
    path: "/fr/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___fr"
  }, {
    path: "/he/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___he"
  }, {
    path: "/he/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___he"
  }, {
    path: "/hi/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___hi"
  }, {
    path: "/hi/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___hi"
  }, {
    path: "/hu/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___hu"
  }, {
    path: "/hu/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___hu"
  }, {
    path: "/ko/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ko"
  }, {
    path: "/ko/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___ko"
  }, {
    path: "/nl/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___nl"
  }, {
    path: "/nl/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___nl"
  }, {
    path: "/ro/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ro"
  }, {
    path: "/ro/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___ro"
  }, {
    path: "/ru/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ru"
  }, {
    path: "/ru/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___ru"
  }, {
    path: "/ur/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___ur"
  }, {
    path: "/ur/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___ur"
  }, {
    path: "/blog/:slug?",
    component: _375eaf2d,
    name: "blog-slug___en"
  }, {
    path: "/properties/:slug",
    component: _ba81fd04,
    name: "properties-slug___en"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
