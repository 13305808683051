export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const FAQsComponent = () => import('../../components/FAQsComponent.vue' /* webpackChunkName: "components/f-a-qs-component" */).then(c => wrapFunctional(c.default || c))
export const FooterComponent = () => import('../../components/FooterComponent.vue' /* webpackChunkName: "components/footer-component" */).then(c => wrapFunctional(c.default || c))
export const FormPopup = () => import('../../components/FormPopup.vue' /* webpackChunkName: "components/form-popup" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponent = () => import('../../components/HeaderComponent.vue' /* webpackChunkName: "components/header-component" */).then(c => wrapFunctional(c.default || c))
export const LanguageSelector = () => import('../../components/LanguageSelector.vue' /* webpackChunkName: "components/language-selector" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const RequestComponent = () => import('../../components/RequestComponent.vue' /* webpackChunkName: "components/request-component" */).then(c => wrapFunctional(c.default || c))
export const SimpleContactForm = () => import('../../components/SimpleContactForm.vue' /* webpackChunkName: "components/simple-contact-form" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const StepsComponent = () => import('../../components/StepsComponent.vue' /* webpackChunkName: "components/steps-component" */).then(c => wrapFunctional(c.default || c))
export const FloatingAgent = () => import('../../components/floating/Agent.vue' /* webpackChunkName: "components/floating-agent" */).then(c => wrapFunctional(c.default || c))
export const HomepageConsultant = () => import('../../components/homepage/Consultant.vue' /* webpackChunkName: "components/homepage-consultant" */).then(c => wrapFunctional(c.default || c))
export const HomepageHero = () => import('../../components/homepage/Hero.vue' /* webpackChunkName: "components/homepage-hero" */).then(c => wrapFunctional(c.default || c))
export const HomepageOpportunities = () => import('../../components/homepage/Opportunities.vue' /* webpackChunkName: "components/homepage-opportunities" */).then(c => wrapFunctional(c.default || c))
export const HomepagePartners = () => import('../../components/homepage/Partners.vue' /* webpackChunkName: "components/homepage-partners" */).then(c => wrapFunctional(c.default || c))
export const HomepageServices = () => import('../../components/homepage/Services.vue' /* webpackChunkName: "components/homepage-services" */).then(c => wrapFunctional(c.default || c))
export const HomepageTestimonials = () => import('../../components/homepage/Testimonials.vue' /* webpackChunkName: "components/homepage-testimonials" */).then(c => wrapFunctional(c.default || c))
export const HomepageWhy = () => import('../../components/homepage/Why.vue' /* webpackChunkName: "components/homepage-why" */).then(c => wrapFunctional(c.default || c))
export const ArticleRenderHtml = () => import('../../components/article/RenderHtml.vue' /* webpackChunkName: "components/article-render-html" */).then(c => wrapFunctional(c.default || c))
export const CareersForm = () => import('../../components/careers/Form.vue' /* webpackChunkName: "components/careers-form" */).then(c => wrapFunctional(c.default || c))
export const CareersHero = () => import('../../components/careers/Hero.vue' /* webpackChunkName: "components/careers-hero" */).then(c => wrapFunctional(c.default || c))
export const CareersInfo = () => import('../../components/careers/Info.vue' /* webpackChunkName: "components/careers-info" */).then(c => wrapFunctional(c.default || c))
export const InputBudgetSelect = () => import('../../components/input/BudgetSelect.vue' /* webpackChunkName: "components/input-budget-select" */).then(c => wrapFunctional(c.default || c))
export const InputCv = () => import('../../components/input/Cv.vue' /* webpackChunkName: "components/input-cv" */).then(c => wrapFunctional(c.default || c))
export const InputDubaiSelect = () => import('../../components/input/DubaiSelect.vue' /* webpackChunkName: "components/input-dubai-select" */).then(c => wrapFunctional(c.default || c))
export const InputLanguagesSelect = () => import('../../components/input/LanguagesSelect.vue' /* webpackChunkName: "components/input-languages-select" */).then(c => wrapFunctional(c.default || c))
export const InputPhone = () => import('../../components/input/Phone.vue' /* webpackChunkName: "components/input-phone" */).then(c => wrapFunctional(c.default || c))
export const InputWorkSelect = () => import('../../components/input/WorkSelect.vue' /* webpackChunkName: "components/input-work-select" */).then(c => wrapFunctional(c.default || c))
export const InputYearsExperienceSelect = () => import('../../components/input/YearsExperienceSelect.vue' /* webpackChunkName: "components/input-years-experience-select" */).then(c => wrapFunctional(c.default || c))
export const PropertiesFilters = () => import('../../components/properties/Filters.vue' /* webpackChunkName: "components/properties-filters" */).then(c => wrapFunctional(c.default || c))
export const PropertiesHero = () => import('../../components/properties/Hero.vue' /* webpackChunkName: "components/properties-hero" */).then(c => wrapFunctional(c.default || c))
export const PropertyAgent = () => import('../../components/property/Agent.vue' /* webpackChunkName: "components/property-agent" */).then(c => wrapFunctional(c.default || c))
export const PropertyBrochureAndFloorPlan = () => import('../../components/property/BrochureAndFloorPlan.vue' /* webpackChunkName: "components/property-brochure-and-floor-plan" */).then(c => wrapFunctional(c.default || c))
export const PropertyDetails = () => import('../../components/property/Details.vue' /* webpackChunkName: "components/property-details" */).then(c => wrapFunctional(c.default || c))
export const PropertyFeatures = () => import('../../components/property/Features.vue' /* webpackChunkName: "components/property-features" */).then(c => wrapFunctional(c.default || c))
export const PropertyFormPopup = () => import('../../components/property/FormPopup.vue' /* webpackChunkName: "components/property-form-popup" */).then(c => wrapFunctional(c.default || c))
export const PropertyHero = () => import('../../components/property/Hero.vue' /* webpackChunkName: "components/property-hero" */).then(c => wrapFunctional(c.default || c))
export const PropertyImages = () => import('../../components/property/Images.vue' /* webpackChunkName: "components/property-images" */).then(c => wrapFunctional(c.default || c))
export const PropertyInfo = () => import('../../components/property/Info.vue' /* webpackChunkName: "components/property-info" */).then(c => wrapFunctional(c.default || c))
export const PropertyMap = () => import('../../components/property/Map.vue' /* webpackChunkName: "components/property-map" */).then(c => wrapFunctional(c.default || c))
export const PropertyPaymentPlans = () => import('../../components/property/PaymentPlans.vue' /* webpackChunkName: "components/property-payment-plans" */).then(c => wrapFunctional(c.default || c))
export const PropertySchools = () => import('../../components/property/Schools.vue' /* webpackChunkName: "components/property-schools" */).then(c => wrapFunctional(c.default || c))
export const WidgetABTest = () => import('../../components/widget/ABTest.vue' /* webpackChunkName: "components/widget-a-b-test" */).then(c => wrapFunctional(c.default || c))
export const WidgetFifthVariation = () => import('../../components/widget/FifthVariation.vue' /* webpackChunkName: "components/widget-fifth-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetFirstVariation = () => import('../../components/widget/FirstVariation.vue' /* webpackChunkName: "components/widget-first-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetFourthVariation = () => import('../../components/widget/FourthVariation.vue' /* webpackChunkName: "components/widget-fourth-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetSecondVariation = () => import('../../components/widget/SecondVariation.vue' /* webpackChunkName: "components/widget-second-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetSeventhVariation = () => import('../../components/widget/SeventhVariation.vue' /* webpackChunkName: "components/widget-seventh-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetSixthVariation = () => import('../../components/widget/SixthVariation.vue' /* webpackChunkName: "components/widget-sixth-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetThirdVariation = () => import('../../components/widget/ThirdVariation.vue' /* webpackChunkName: "components/widget-third-variation" */).then(c => wrapFunctional(c.default || c))
export const WidgetTopProperties = () => import('../../components/widget/TopProperties.vue' /* webpackChunkName: "components/widget-top-properties" */).then(c => wrapFunctional(c.default || c))
export const PropertiesInputsDeveloper = () => import('../../components/properties/inputs/Developer.vue' /* webpackChunkName: "components/properties-inputs-developer" */).then(c => wrapFunctional(c.default || c))
export const PropertiesInputsLocation = () => import('../../components/properties/inputs/Location.vue' /* webpackChunkName: "components/properties-inputs-location" */).then(c => wrapFunctional(c.default || c))
export const PropertiesInputsStatus = () => import('../../components/properties/inputs/Status.vue' /* webpackChunkName: "components/properties-inputs-status" */).then(c => wrapFunctional(c.default || c))
export const PropertiesInputsType = () => import('../../components/properties/inputs/Type.vue' /* webpackChunkName: "components/properties-inputs-type" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
