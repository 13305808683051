
import CountryFlag from 'vue-country-flag'
import ClickOutside from 'vue-click-outside'

export default {
    directives: {
        ClickOutside,
    },
    components: { CountryFlag },
    data () {
        return {
            showDropdown: false,
        }
    },
    methods: {
        hide () {
            this.$nextTick(() => {
                this.showDropdown = false
            })
        },
        setLocale (lang) {
            this.$i18n.setLocale(lang.code)
        },
    },
}
