
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            options: [
                { value: true, label: this.$t('FORM.YES') },
                { value: false, label: this.$t('FORM.NO') },
            ],
        }
    },
    methods: {
        input (value) {
            this.$emit('input', value)
            this.showOptions = false
        },
    },
}
