
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
    components: {
        VuePhoneNumberInput: () => import('vue-phone-number-input'),
    },
    props: {
        showQuestion: {
            type: Boolean,
            default: true,
        },
        alternativeBg: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            phoneNumber: '',
            isValid: false,
            translations: {
                phoneNumberLabel: this.$t('FORM.PHONE'),
                countrySelectorLabel: this.$t('FORM.COUNTRY_CODE'),
            },
        }
    },
    methods: {
        update (values) {
            this.isValid = values.isValid
            if (values.isValid) {
                this.$emit('input', values.formattedNumber)
                this.$emit('country', values.countryCode)
            } else {
                this.$emit('input', '')
            }
        },
    },
}
