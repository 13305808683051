import { render, staticRenderFns } from "./FormPopup.vue?vue&type=template&id=c53fda94&scoped=true&"
import script from "./FormPopup.vue?vue&type=script&lang=js&"
export * from "./FormPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c53fda94",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactForm: require('/usr/src/app/components/ContactForm.vue').default})
