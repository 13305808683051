import { render, staticRenderFns } from "./HeaderComponent.vue?vue&type=template&id=20611df0&scoped=true&"
import script from "./HeaderComponent.vue?vue&type=script&lang=js&"
export * from "./HeaderComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20611df0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LanguageSelector: require('/usr/src/app/components/LanguageSelector.vue').default})
