
export default {
    props: {
        value: {
            type: String,
            default: '<=200',
        },
    },
    data () {
        return {
            options: [
                { value: '<=200k', label: this.$t('FORM.UP_TO_200') },
                { value: '200k-500k', label: '200k-500k' },
                { value: '500k-1M', label: '500k-1M' },
                { value: '1M-5M', label: '1M-5M' },
                { value: '5M+', label: '5M+' },
            ],
        }
    },
    methods: {
        input (value) {
            this.$emit('input', value)
            this.showOptions = false
        },
    },
}
