
import handleToken from '@/mixins/handleToken'

export default {
    mixins: [handleToken],
    props: {
        transparent: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            isOpen: false,
            items: [
                {
                    text: this.$t('HEADER.ABOUT'),
                    to: '/#about',
                },
                {
                    text: this.$t('HEADER.SERVICES'),
                    to: '/#services',
                },
                {
                    text: this.$t('HEADER.PROPERTIES'),
                    to: '/properties',
                },
                {
                    text: this.$t('HEADER.CAREERS'),
                    to: '/careers',
                },
            ],
        }
    },
    methods: {
        drawer () {
            this.isOpen = !this.isOpen
        },
        async sendWhatsAppMessage () {
            window.open(
                `https://api.whatsapp.com/send?phone=${
                    this.$t('WHATS_APP.PHONE_NUMBER')}&text=${
                    this.$t('WHATS_APP.TEXT')}`,
                '_blank',
            )
            this.$gtm.push({ event: 'contactViaWA' })
            await this.$axios({
                method: 'post',
                url: 'started_chat',
                baseURL: '/',
                data: this.getToken(),
            })
        },
    },
}
